import { useState } from 'react';
import BurgerIcon from './BurgerIcon';
import { defaultStyles } from '@/utils/defaultStyles';

export const routes = [
  {
    label: 'Czym jest trening?',
    id: 'what-is-training',
  },
  {
    label: 'Usługi',
    id: 'services',
  },
  {
    label: 'O mnie',
    id: 'about-me',
  },
  {
    label: 'Cennik',
    id: 'pricing',
  },
  {
    label: 'Kontakt',
    id: 'contact',
  },
];

export function ModalButton(props: { secondary?: boolean }) {
  return (
    <button
      onClick={() =>
        (
          document.getElementById('basic-modal') as HTMLDialogElement | null
        )?.showModal()
      }
      className={`cursor-pointer text-[16px] font-semibold flex flex-row justify-center items-center outline-none rounded-[63px] py-4 px-4 w-[240px] border border-solid ${props.secondary ? 'text-white bg-transparent border-white' : 'text-[#0D81FF] bg-white border-transparent'}`}
    >
      <div>Umów się na sesję</div>
    </button>
  );
}

function Navbar(props: { noHero?: boolean }) {
  const [isBurgerToggled, setIsBurgerToggled] = useState(false);

  return (
    <div
      className={`${props.noHero ? 'bg-[#0D81FF] rounded-b-[31px] mb-12' : 'bg-gradient-to-b from-[#0D81FF] to-transparent'} ${defaultStyles.paddingBig}`}
    >
      <nav className="relative navbar bg-transparent h-[108px]">
        <div className="navbar-start flex flex-row items-center">
          <img
            loading="lazy"
            src="/logo.png"
            className="w-[96px] h-[104px] cursor-pointer"
            onClick={() => window.location.assign('/')}
            alt="Mental step logo"
          />
        </div>
        <div className="navbar-end flex xl:hidden flex-col justify-center items-end">
          <BurgerIcon
            isToggled={isBurgerToggled}
            onClick={() => setIsBurgerToggled(!isBurgerToggled)}
          />
        </div>
        {isBurgerToggled && (
          <div className="absolute top-[100%] left-0 flex flex-col gap-2 items-start p-4 bg-[#0D81FF] w-[90%] rounded-[24px] z-50">
            {routes.map((item, index) => (
              <div
                key={index}
                className="btn btn-ghost text-white font-[300] text-[16px]"
                onClick={() => {
                  if (item.id) {
                    if (window.location.pathname != '/') {
                      window.location.assign('/');
                    }
                    document
                      .getElementById(item.id)!
                      .scrollIntoView({ behavior: 'smooth' });
                  }
                }}
              >
                {item.label}
              </div>
            ))}
            <ModalButton />
          </div>
        )}
        <div className="navbar-center hidden xl:flex flex-row md:gap-2 items-center">
          {routes.map((item, index) => (
            <div
              key={index}
              className="btn btn-ghost text-white font-[300] text-[16px]"
              onClick={() => {
                if (item.id) {
                  if (window.location.pathname != '/') {
                    window.location.assign('/');
                  }
                  document
                    .getElementById(item.id)!
                    .scrollIntoView({ behavior: 'smooth' });
                }
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
        <div className="navbar-end hidden xl:flex flex-row md:gap-2 items-center ml-8">
          <ModalButton />
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
