import { modalIds } from '@/constants';

function Button(props: { title: string; route: string }) {
  return (
    <div
      className="flex flex-row justify-between items-center gap-4 rounded-[44px] px-8 py-4 border border-solid border-black cursor-pointer"
      onClick={() => window.location.assign(props.route)}
    >
      <div className="text-[16px] text-black font-bold">{props.title}</div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22Z"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.74 15.53L14.26 12L10.74 8.46997"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

export function BasicModal() {
  return (
    <dialog id={modalIds.basic} className="modal !border-0">
      <div className="modal-box !w-[80%] lg:!w-[91%]">
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
        </form>
        <div className="flex flex-col gap-4">
          <div className="text-black text-[16px] font-bold pb-2 border border-solid border-[#C4C4C4] border-t-0 border-r-0 border-l-0">
            Wybierz pakiet treningu mentalnego
          </div>
          <Button title="TRENING GRUPOWY" route="/checkout?group=true" />
          <Button title="SESJA INDYWIDUALNA" route="/checkout?group=false" />
        </div>
      </div>
    </dialog>
  );
}
