import { defaultStyles } from '@/utils/defaultStyles';
import { routes } from './Navbar';
import SocialIcons from './SocialIcons';

function Footer() {
  return (
    <footer
      className={`bg-[#F2F2F2] ${defaultStyles.paddingBig} pt-12 pb-6 flex flex-col gap-8`}
      id="contact"
    >
      <div className="flex lg:flex-row flex-col items-center justify-between gap-4 border border-solid border-[#201F22] border-l-0 border-t-0 border-r-0 pb-4">
        <img
          loading="lazy"
          width={96}
          height={104}
          alt="Logo"
          src="/logo-black.png"
        />
        <div className="flex flex-row flex-wrap gap-4 justify-center items-center">
          {routes.map((item, index) => (
            <div
              key={index}
              className="btn btn-ghost text-black font-[300] text-[16px]"
              onClick={() => {
                if (item.id) {
                  if (window.location.pathname != '/') {
                    window.location.assign('/');
                  }
                  document
                    .getElementById(item.id)!
                    .scrollIntoView({ behavior: 'smooth' });
                }
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
        <SocialIcons />
      </div>
      <div className="flex lg:flex-row flex-col gap-4 justify-between items-center">
        <div className="text-[#52525B] text-[16px]">
          © Copyright {new Date().getFullYear()}, All Rights Reserved
        </div>
        <div className="flex lg:flex-row flex-col items-center gap-4">
          <div
            className="text-[#52525B] text-[16px] cursor-pointer"
            onClick={() => window.location.assign('/policy')}
          >
            Polityka prywatności
          </div>
          <div
            className="text-[#52525B] text-[16px] cursor-pointer"
            onClick={() => window.location.assign('/terms')}
          >
            Regulamin
          </div>
          <div
            className="text-[#52525B] text-[16px] cursor-pointer"
            onClick={() => window.location.assign('/rodo')}
          >
            RODO
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
