import type { AppProps } from 'next/app';
import { ToastContainer } from 'react-toastify';
import 'react-multi-carousel/lib/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-dropdown/style.css';
import '../styles/globals.css';

import AppWrapper from '../components/AppWrapper';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <AppWrapper>
        <Component {...pageProps} />
      </AppWrapper>
      <ToastContainer />
    </>
  );
}
